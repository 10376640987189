.loginPage {
  height: 100%;
  width: 100%;

  .inputWrpr {
    width: 270px;

    label {
      color: rgba(46, 125, 165, 0.829);

      padding-left: 5px;
    }

    .input {
      height: 42px;
      background-color: white;
      border: 1px solid rgba(46, 125, 165, 0.259);
      border-radius: 6px;
      padding: 0px 14px;
    }
  }

  .loginBtn {
    width: 270px;
    height: 42px;
    background-color: #00a8ff;
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      background-color: #93a4ad65;
      color: white;
    }
  }

  &.error {
    .inputWrpr {
      label {
        color: #be0808;
      }

      .input {
        border-color: #be0808;
        color: #be0808;
      }
    }

    .loginBtn {
      background-color: #be0808;
    }
  }
}
