.reportDetails {
  height: 100%;

  .pageHeader {
    padding-top: 46px;
    margin-left: 38px;

    .sub {
      font-size: 0.8em;
      opacity: 0.6;
    }

    .backArrow {
      transform: rotate(180deg);
      display: inline-block;
      position: relative;
      cursor: pointer;
    }
  }

  .contentWrapper {
    padding: 20px 38px;
    height: calc(100% - 70px);

    .commentsSection {
      border: 1px solid #9b9b9b;
      padding: 20px 20px 0px;
      border-radius: 6px;
      margin-bottom: 28px;

      .line {
        min-height: 24px;
        margin-bottom: 12px;

        .label {
          color: #575757;
        }
      }
    }

    .line {
      min-height: 24px;
      margin-bottom: 12px;

      .label {
        color: #898989;
        width: 220px;
      }

      .val {
        color: rgb(6 36 136);
      }
    }
    .link {
      background-color: #d2d7e8;
      color: #062488;
      text-decoration: none;
      padding: 10px 30px;
      border-radius: 6px;
      margin-bottom: 20px;
    }

    .thumbnail {
      height: 126px;
      width: 214px;
      background-size: cover;
      box-shadow: 3px 0px 11px 0px rgb(0 0 0 / 20%);
      border-radius: 3px;
      overflow: hidden;
      cursor: pointer;

      &.recording {
        background: rgb(248, 248, 248);
        color: #062488;
        text-decoration: none;
        font-weight: 800;
      }
    }
  }

  .imagePopup {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0px;
    top: 0px;
    backdrop-filter: blur(2.5px);
    background-color: rgba(0, 0, 0, 0.5);

    .imagePopupContent {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      max-height: 90%;
      overflow: auto;
      max-width: 96%;
      width: fit-content;

      .closeButton {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 1;
        padding: 14px;
        background-color: #fff;
        box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 30%);
        border-radius: 50%;
        line-height: 0px;

        svg {
          // margin-bottom: -2px;
        }
      }

      img {
        height: 99%;
        // max-width: 100%;
        border-radius: 8px;
      }
    }
  }
}
