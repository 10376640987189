.project {
  // background-color: aqua;
  height: 100%;

  .pageHeader {
    padding-top: 46px;
    margin-left: 38px;

    .backArrow {
      transform: rotate(180deg);
      display: inline-block;
      position: relative;
      cursor: pointer;
    }
  }

  .contentWrapper {
    padding: 20px 38px;
    height: calc(100% - 70px);

    .tabs {
      .tab {
        background-color: #fff;
        padding: 10px 34px;
        border-radius: 4px;
        margin-right: 8px;
        border: 1px solid #e6e6e6;
        cursor: pointer;

        &.active {
          border-color: #062488;
          color: #062488;
        }
      }
    }

    .setUp {
      padding: 20px;
      background: #fff;
      box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 20%);
      border-radius: 6px;
      margin-top: 20px;
    }

    .reports {
      height: calc(100% - 90px);
      overflow: auto;

      .reportLine {
        border-bottom: 1px solid rgb(168, 168, 168);
        text-decoration: none;
        color: #062488;
        padding: 0px 12px;
        transition: all 0.3s;

        &:hover {
          background-color: #f5f5f5;
          // text-decoration: underline;
        }

        a {
          text-decoration: none;
        }

        img {
          border: 1px solid rgb(175, 175, 175);
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
          margin: 4px 0px;
          border-radius: 4px;
        }
      }
    }
  }

  .setupBtn {
    background-color: #062488;
    border-radius: 4px;
    padding: 10px 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
