.projects {
  // background-color: aqua;
  height: 100%;

  .addBtn {
    background-color: #062488;
    border-radius: 4px;
    padding: 10px 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .pageHeader {
    padding-top: 46px;
    margin-left: 38px;
    padding-right: 18px;
  }

  .listWrapper {
    padding: 20px 38px;
    height: calc(100% - 85px);
    align-items: flex-start;

    .noProjectsWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 20px;
      font-size: 18px;
      color: #062488;
      flex-flow: column;
    }

    .listItem {
      border-radius: 4px;
      padding: 26px 32px;
      background-color: white;
      box-shadow: 4px 0px 14px 0px rgb(0 0 0 / 06%);
      margin-right: 18px;
      width: 270px;
      color: #333;
      text-decoration: none;
    }
  }
}
