.addProject {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  backdrop-filter: blur(2.5px);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 30px 38px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .inputWrapper {
      margin-bottom: 10px;
      margin-top: 18px;

      label {
        margin-bottom: 8px;
      }

      input {
        display: block;
        width: 100%;
        padding: 10px;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        font-size: 16px;
        height: 42px;
      }
    }

    .saveBtn {
      background-color: #062488;
      border-radius: 4px;
      padding: 10px 22px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: none;
      margin-top: 34px;
    }
  }
}
