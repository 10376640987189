.nav {
  width: 270px;
  border-right: 1px solid #e8e8eb;
  box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 6%);
  z-index: 1;

  .logoWrpr {
    padding: 10px 20px;
    height: 90px;
    color: rgb(6, 36, 136);
    border-bottom: 1px solid #e8e8eb;
    text-decoration: none;
  }

  .linksWrpr {
    padding: 15px 20px;

    .link {
      height: 48px;
      border-radius: 6px;
      padding: 0px 18px;
      color: rgb(51, 51, 51);
      text-decoration: none;

      &.active {
        background-color: #e8e8eb;
      }
    }
  }
  .account {
    border-top: 1px solid #dbdbdb;
    padding: 24px 24px;

    a {
      text-decoration: none;
      color: #161066;
    }

    .name {
      width: calc(100% - 90px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // font-size: 14px;
    }

    .profileIcon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #9f0b0b;
      color: white;
    }

    .gear {
      line-height: 0px;
    }
  }
}
