.settings {
  height: 100%;

  .pageHeader {
    padding-top: 46px;
    margin-left: 38px;
    display: flex;

    .addBtn {
      background-color: #062488;
      border-radius: 4px;
      padding: 10px 22px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 18px;
      margin-left: auto;
    }
  }

  .users {
    padding: 30px 38px;

    .line {
      padding: 0px 4px;
      height: 44px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
}
