@import "./mixin.scss";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: initial;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// end reset.css

// Fonts

@font-face {
  font-family: "mont bold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

.ffmb {
  font-family: "mont bold";
}

@font-face {
  font-family: "mont med";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}

.ffmm {
  font-family: "mont med";
}

@font-face {
  font-family: "mont reg";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

.ffmr {
  font-family: "mont reg";
}

// Font sizes

.fs30 {
  font-size: 30px;
}

.fs20 {
  font-size: 20px;
}

.fs18 {
  font-size: 18px;
}

.fs16 {
  font-size: 16px;
}

.fs14 {
  font-size: 14px;
}

.fs12 {
  font-size: 12px;
}

html,
body,
#root,
.app {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
  position: relative;
}

.df {
  display: flex;

  &.ffc {
    flex-flow: column;
  }

  &.aic {
    align-items: center;
  }

  &.jcc {
    justify-content: center;
  }

  &.acc {
    align-items: center;
    justify-content: center;
  }

  .mla {
    margin-left: auto;
  }

  .mta {
    margin-top: auto;
  }
}
